<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5">
        Paramétrage
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="addNew" color="primary" dark>
        <v-icon left>mdi-plus</v-icon>
        Ajouter
      </v-btn>
    </v-toolbar>

    <!-- Table des paramétrages -->
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          class="mb-4"
        ></v-text-field>

        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <!-- Colonne de couleur -->
          <template v-slot:item.parametrage.couleur="{ item }">
            <v-chip :color="item.parametrage.couleur" small class="white--text">
              {{ item.parametrage.couleur }}
            </v-chip>
          </template>

          <!-- Colonne d'actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                  color="warning"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Modifier</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                  color="error"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialog d'ajout/modification -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.libelle"
                    label="Libellé"
                    :rules="libelleRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.choix"
                    :items="choixOptions"
                    label="Choix"
                    :rules="choixRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <label style="
                    font-size: 16px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  ">Couleur
                  <v-chip :color="editedItem.couleur" class="white--text">
                    {{ editedItem.couleur }}
                  </v-chip>
                  </label>
                  
                  <v-color-picker
                    v-model="editedItem.couleur"
                    class="ma-2 mx-auto custom-select"
                    show-swatches
                    swatches-max-height="200"
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de confirmation de suppression -->
    <v-dialog v-model="dialogDelete" max-width="620px">
      <v-card>
        <v-card-title class="text-h5">
          Êtes-vous sûr de vouloir supprimer ce paramétrage ?
        </v-card-title>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn color=""  @click="closeDelete">Non</v-btn>
          <v-btn color="error"  @click="deleteItemConfirm">Oui</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ParametrageService from "@/Services/SupportVision/ParametrageService";

export default {
  name: "Parametrage",
  data: () => ({
    search: "",
    loading: false,
    dialog: false,
    dialogDelete: false,
    valid: false,
    headers: [
      { text: "Libellé", value: "parametrage.libelle" },
      { text: "Choix", value: "parametrage.choix" },
      { text: "Couleur", value: "parametrage.couleur" },
      { text: "Actions", value: "actions", sortable: false , align: 'end'},
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      libelle: "",
      choix: "",
      couleur: "#000000",
    },
    defaultItem: {
      libelle: "",
      choix: "",
      couleur: "#000000",
    },
    choixOptions: ["Projet", "Développement"],
    libelleRules: [
      (v) =>
        (v !== null && v !== undefined && v !== "") || "Le libellé est requis",
      (v) =>
        !v || v.length <= 50 || "Le libellé doit faire moins de 50 caractères",
    ],
    choixRules: [
      (v) =>
        (v !== null && v !== undefined && v !== "") || "Le choix est requis",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau paramétrage"
        : "Modifier le paramétrage";
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
    dialogDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        this.loading = true;
        const response = await ParametrageService.getParametrages();

        // L'API retourne directement un tableau
        this.items = response.data || [];
      } catch (error) {
        console.error("Erreur lors du chargement des paramétrages:", error);
          this.$nError('Erreur lors du chargement des paramétrages');
        this.items = [];
      } finally {
        this.loading = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item.parametrage,
      };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item.parametrage,
      };
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        // Envoi de l'ID pour la suppression
        await ParametrageService.deleteParametrage(this.editedItem._id);
        this.items.splice(this.editedIndex, 1);
          this.$nSuccess('Paramétrage supprimé avec succès');
      } catch (error) {
        console.error("Erreur lors de la suppression:", error);
          this.$nError('Erreur lors de la suppression');
      } finally {
        this.closeDelete();
      }
    },

    resetForm() {
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }
      });
    },

    close() {
      this.dialog = false;
      this.resetForm();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        return;
      }

      try {
        if (this.editedIndex > -1) {
          // Edition avec l'ID existant
          const response = await ParametrageService.editParametrage({
            ...this.editedItem,
            _id: this.items[this.editedIndex]._id,
          });
          // Mise à jour directe avec la réponse
          Object.assign(this.items[this.editedIndex], response.data);
          this.$nSuccess('Paramétrage modifié avec succès');
        } else {
          // Création d'un nouveau paramétrage
          const response = await ParametrageService.saveParametrage(
            this.editedItem
          );
          // Ajout direct de la réponse au tableau
          this.items.push(response.data);
          this.$nSuccess('Paramétrage créé avec succès');
        }
        this.close();
      } catch (error) {
        console.error("Erreur lors de la sauvegarde:", error);
          this.$nError('Erreur lors de la sauvegarde');
      }
    },

    addNew() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  background: white;
}

/* .custom-select avec une hauter max et scrollable */
.custom-select {
  max-height: 300px;
  overflow-y: auto;
}
</style>
